import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "api-reference"
    }}>{`API Reference`}</h1>
    <h2 {...{
      "id": "http-post-standard-siri-20"
    }}>{`HTTP POST (Standard SIRI 2.0)`}</h2>
    <p>{`Publish/subscribe: `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/subscribe"
      }}>{`https://api.entur.io/realtime/v1/subscribe`}</a></p>
    <p>{`Request/Response: `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/services"
      }}>{`https://api.entur.io/realtime/v1/services`}</a></p>
    <p>{`Get data for only one operator, add `}<inlineCode parentName="p">{`/<Codespace-ID>`}</inlineCode>{` to the end of URL. You can find the operator's Codespace-ID in the list located at Intro.`}</p>
    <p>{`Example: `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/services/RUT"
      }}>{`https://api.entur.io/realtime/v1/services/RUT`}</a></p>
    <h2 {...{
      "id": "http-get-standard-siri-20-lite"
    }}>{`HTTP GET (Standard SIRI 2.0 Lite)`}</h2>
    <p>{`HTTP GET `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/rest/sx"
      }}>{`https://api.entur.io/realtime/v1/rest/sx`}</a></p>
    <p>{`HTTP GET `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/rest/vm"
      }}>{`https://api.entur.io/realtime/v1/rest/vm`}</a></p>
    <p>{`HTTP GET `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/rest/et"
      }}>{`https://api.entur.io/realtime/v1/rest/et`}</a></p>
    <b>Note:</b>Limited to 4 requests per minute. If more frequent updates are
needed, publish/subscribe should be used.
    <h2 {...{
      "id": "optional-http-parameters"
    }}>{`Optional HTTP-parameters`}</h2>
    <h3 {...{
      "id": "datasetid"
    }}>{`datasetId`}</h3>
    <ul>
      <li parentName="ul">{`E.g. `}<em parentName="li">{`datasetId=RUT`}</em>{`. You can find the operator's Codespace-ID in the list located at Intro.`}</li>
      <li parentName="ul">{`Limits the results to original dataset-provider`}</li>
    </ul>
    <p>{`Example: `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/rest/et?datasetId=RUT"
      }}>{`https://api.entur.io/realtime/v1/rest/et?datasetId=RUT`}</a></p>
    <h3 {...{
      "id": "excludeddatasetids"
    }}>{`excludedDatasetIds`}</h3>
    <ul>
      <li parentName="ul">{`Comma-separated list of datasets to exclude from result`}</li>
      <li parentName="ul">{`E.g. `}<em parentName="li">{`excludedDatasetIds=RUT,NSB`}</em></li>
      <li parentName="ul">{`Limits result by `}<em parentName="li">{`excluding`}</em>{` data from the provided datasetIds`}</li>
      <li parentName="ul"><em parentName="li">{`Note:`}</em>{` Valid for VM and ET requests only
(I.e. the opposite of parameter `}<inlineCode parentName="li">{`datasetId`}</inlineCode>{`)`}</li>
    </ul>
    <p>{`Example: `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/rest/et?excludedDatasetIds=BNR,OST,RUT"
      }}>{`https://api.entur.io/realtime/v1/rest/et?excludedDatasetIds=BNR,OST,RUT`}</a></p>
    <h2 {...{
      "id": "useoriginalid"
    }}>{`useOriginalId`}</h2>
    <p>{`By default we return data with IDs mapped to those used in the static route data. Instead, if you want the original IDs delivered from the underlying system, you can add a URL parameter useOriginalId = true.`}</p>
    <p>{`NB: This is also supported by SIRI Publish/subscribe and Request/Response`}</p>
    <p>{`Example: `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/rest/sx?useOriginalId=true"
      }}>{`https://api.entur.io/realtime/v1/rest/sx?useOriginalId=true`}</a></p>
    <h3 {...{
      "id": "maxsize"
    }}>{`maxSize`}</h3>
    <ul>
      <li parentName="ul">{`E.g. `}<em parentName="li">{`maxSize=10`}</em>{` (default is 1500)`}</li>
      <li parentName="ul">{`Limits the number of elements in the returned result. `}<em parentName="li">{`requestorId`}</em>{` will be used to track changes since last request and is provided in result. An id will be created and returned if not provided.`}</li>
      <li parentName="ul">{`If more data exists, the attribute `}<em parentName="li">{`MoreData`}</em>{` will be set to `}<em parentName="li">{`true`}</em></li>
    </ul>
    <p>{`Example: `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/rest/et?maxSize=10"
      }}>{`https://api.entur.io/realtime/v1/rest/et?maxSize=10`}</a></p>
    <h3 {...{
      "id": "requestorid"
    }}>{`requestorId`}</h3>
    <ul>
      <li parentName="ul">{`E.g. `}<em parentName="li">{`requestorId=757c9284-0f12-4a46-a557-33a19e80119b`}</em></li>
      <li parentName="ul">{`Value needs to be unique for as long as only updated data are needed, a UUID should be used`}</li>
      <li parentName="ul">{`First request creates a short lived session (timeout after e.g. 5 minutes)`}</li>
      <li parentName="ul">{`Subsequent requests returns only changes since last request with the same requestorId`}</li>
    </ul>
    <p>{`Example: `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/rest/sx?requestorId=757c9284-0f12-4a46-a557-33a19e80119b"
      }}>{`https://api.entur.io/realtime/v1/rest/sx?requestorId=757c9284-0f12-4a46-a557-33a19e80119b`}</a></p>
    <h2 {...{
      "id": "http-get-gtfs-rt"
    }}>{`HTTP GET (GTFS-RT)`}</h2>
    <p>{`HTTP GET `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/gtfs-rt/trip-updates"
      }}>{`https://api.entur.io/realtime/v1/gtfs-rt/trip-updates`}</a></p>
    <p>{`HTTP GET `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/gtfs-rt/vehicle-positions"
      }}>{`https://api.entur.io/realtime/v1/gtfs-rt/vehicle-positions`}</a></p>
    <p>{`HTTP GET `}<a parentName="p" {...{
        "href": "https://api.entur.io/realtime/v1/gtfs-rt/alerts"
      }}>{`https://api.entur.io/realtime/v1/gtfs-rt/alerts`}</a></p>
    <b>Note:</b>Fetches complete dataset. Currently only updated every 15 seconds.
    <h3 {...{
      "id": "datasource"
    }}>{`datasource`}</h3>
    <ul>
      <li parentName="ul">{`E.g. `}<em parentName="li">{`datasource=RUT`}</em>{`. You can find the operator's Codespace-ID in the list located at Intro.`}</li>
      <li parentName="ul">{`Limits the results to original dataset-provider`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      